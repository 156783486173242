import React from "react"

const Gatsby: React.FC<IconProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      width="2em"
      height="2em"
      viewBox="0 0 48 48"
      strokeWidth="1"
      stroke="currentColor"
      {...props}
    >
      <path
        fill="#fff"
        d="M44,24c0,11.044-8.956,20-20,20S4,35.044,4,24S12.956,4,24,4S44,12.956,44,24z"
      ></path>
      <linearGradient
        id="_QeCb_GwMoodiBb_c5Ynha_gB16AKgvayey_gr1"
        x1="4"
        x2="44"
        y1="24"
        y2="24"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".002" stopColor="#9c55d4"></stop>
        <stop offset=".003" stopColor="#9c55d4"></stop>
        <stop offset=".337" stopColor="#9751d2"></stop>
        <stop offset=".737" stopColor="#8847cb"></stop>
        <stop offset=".848" stopColor="#8343c8"></stop>
        <stop offset=".89" stopColor="#8042c3"></stop>
        <stop offset=".938" stopColor="#773db6"></stop>
        <stop offset=".988" stopColor="#68369f"></stop>
        <stop offset="1" stopColor="#643499"></stop>
      </linearGradient>
      <path
        fill="url(#_QeCb_GwMoodiBb_c5Ynha_gB16AKgvayey_gr1)"
        d="M24,4C13,4,4,13,4,24s9,20,20,20s20-9,20-20S35,4,24,4z M12.856,35.144	c-3-3-4.571-7-4.571-10.856l15.571,15.427C19.856,39.571,15.856,38.143,12.856,35.144L12.856,35.144z M27.428,39.288L8.714,20.571	c1.571-7,7.857-12.286,15.286-12.286c5.286,0,9.856,2.571,12.714,6.429l-2.144,1.856c-2.427-3.285-6.285-5.427-10.571-5.427	c-5.571,0-10.286,3.571-12.144,8.571l16.429,16.429c4.144-1.429,7.286-5,8.286-9.286h-6.856V24h10	c0,7.429-5.286,13.714-12.286,15.286L27.428,39.288z"
      ></path>
      <path
        d="M24.002,8.285c5.286,0,9.856,2.571,12.714,6.429l-2.144,1.856c-2.427-3.285-6.285-5.427-10.571-5.427 c-5.571,0-10.286,3.571-12.144,8.571l16.429,16.429c4.144-1.429,7.286-5,8.286-9.286h-6.856V24h10 c0,7.429-5.286,13.714-12.286,15.286l-0.002,0.002L8.716,20.571C10.287,13.571,16.573,8.285,24.002,8.285 M24.002,7.785 c-7.491,0-14.125,5.331-15.773,12.676l-0.06,0.268l0.195,0.195l18.713,18.716l0.189,0.189l0.417-0.089 C34.954,38.039,40.217,31.442,40.217,24v-0.5h-0.5h-10h-0.5V24v2.858v0.5h0.5h6.21c-1.085,3.767-3.902,6.856-7.51,8.208 L12.441,19.591c1.93-4.839,6.434-7.948,11.56-7.948c3.951,0,7.752,1.953,10.168,5.224l0.321,0.435l0.408-0.354l2.144-1.856 l0.35-0.303l-0.276-0.372C33.995,10.202,29.215,7.785,24.002,7.785L24.002,7.785z"
        opacity=".07"
      ></path>
      <path
        d="M24.008,7.784c5.213,0,9.994,2.417,13.116,6.631l0.276,0.372l-0.35,0.303l-2.144,1.856L34.497,17.3l-0.321-0.435 c-2.417-3.271-6.218-5.224-10.168-5.224c-5.127,0-9.63,3.109-11.56,7.948l15.975,15.975c3.607-1.352,6.425-4.441,7.51-8.208h-6.21 h-0.5v-0.5v-2.858v-0.5h0.5h10h0.5v0.5c0,7.443-5.263,14.039-12.534,15.741l-0.417,0.089l-0.189-0.189L8.369,20.923l-0.195-0.195 l0.06-0.268C9.883,13.115,16.517,7.784,24.008,7.784 M24.008,7.284c-7.723,0-14.562,5.495-16.261,13.067l-0.06,0.268l-0.06,0.268 l0.195,0.195l0.195,0.195l18.713,18.716l0.189,0.189l0.192,0.192l0.266-0.057l0.417-0.089c7.496-1.754,12.93-8.579,12.93-16.23 v-0.5v-0.5h-0.5h-0.5h-10h-0.5h-0.5v0.5v0.5v2.858v0.5v0.5h0.5h0.5h5.526c-1.129,3.237-3.585,5.853-6.704,7.123L13.037,19.472 c1.915-4.464,6.187-7.33,10.971-7.33c3.793,0,7.444,1.877,9.766,5.021l0.321,0.435l0.321,0.435l0.408-0.354l0.408-0.354 l2.144-1.856l0.35-0.303l0.35-0.303l-0.276-0.372l-0.276-0.372C34.308,9.774,29.381,7.284,24.008,7.284L24.008,7.284z"
        opacity=".05"
      ></path>
    </svg>
  )
}

export default Gatsby
