import React from "react"

const GithubFooter: React.FC<IconProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="3em"
      height="3em"
      viewBox="0 0 100 100"
      {...props}
    >
      <path
        fill="#c7ede6"
        d="M87.215,56.71C88.35,54.555,89,52.105,89,49.5c0-6.621-4.159-12.257-10.001-14.478 C78.999,35.015,79,35.008,79,35c0-11.598-9.402-21-21-21c-9.784,0-17.981,6.701-20.313,15.757C36.211,29.272,34.638,29,33,29 c-7.692,0-14.023,5.793-14.89,13.252C12.906,43.353,9,47.969,9,53.5C9,59.851,14.149,65,20.5,65c0.177,0,0.352-0.012,0.526-0.022 C21.022,65.153,21,65.324,21,65.5C21,76.822,30.178,86,41.5,86c6.437,0,12.175-2.972,15.934-7.614C59.612,80.611,62.64,82,66,82 c4.65,0,8.674-2.65,10.666-6.518C77.718,75.817,78.837,76,80,76c6.075,0,11-4.925,11-11C91,61.689,89.53,58.727,87.215,56.71z"
      ></path>
      <path
        fill="#fdfcef"
        d="M77.5,60.5V61h3v-0.5c0,0,4.242,0,5.5,0c2.485,0,4.5-2.015,4.5-4.5 c0-2.333-1.782-4.229-4.055-4.455C86.467,51.364,86.5,51.187,86.5,51c0-2.485-2.015-4.5-4.5-4.5c-1.438,0-2.703,0.686-3.527,1.736 C78.333,45.6,76.171,43.5,73.5,43.5c-2.761,0-5,2.239-5,5c0,0.446,0.077,0.87,0.187,1.282C68.045,49.005,67.086,48.5,66,48.5 c-1.781,0-3.234,1.335-3.455,3.055C62.364,51.533,62.187,51.5,62,51.5c-2.485,0-4.5,2.015-4.5,4.5s2.015,4.5,4.5,4.5s9.5,0,9.5,0 H77.5z"
      ></path>
      <path
        fill="#472b29"
        d="M73.5,43c-3.033,0-5.5,2.467-5.5,5.5c0,0.016,0,0.031,0,0.047C67.398,48.192,66.71,48,66,48 c-1.831,0-3.411,1.261-3.858,3.005C62.095,51.002,62.048,51,62,51c-2.757,0-5,2.243-5,5s2.243,5,5,5h15.5 c0.276,0,0.5-0.224,0.5-0.5S77.776,60,77.5,60H62c-2.206,0-4-1.794-4-4s1.794-4,4-4c0.117,0,0.23,0.017,0.343,0.032l0.141,0.019 c0.021,0.003,0.041,0.004,0.062,0.004c0.246,0,0.462-0.185,0.495-0.437C63.232,50.125,64.504,49,66,49 c0.885,0,1.723,0.401,2.301,1.1c0.098,0.118,0.241,0.182,0.386,0.182c0.078,0,0.156-0.018,0.228-0.056 c0.209-0.107,0.314-0.346,0.254-0.573C69.054,49.218,69,48.852,69,48.5c0-2.481,2.019-4.5,4.5-4.5 c2.381,0,4.347,1.872,4.474,4.263c0.011,0.208,0.15,0.387,0.349,0.45c0.05,0.016,0.101,0.024,0.152,0.024 c0.15,0,0.296-0.069,0.392-0.192C79.638,47.563,80.779,47,82,47c2.206,0,4,1.794,4,4c0,0.117-0.017,0.23-0.032,0.343l-0.019,0.141 c-0.016,0.134,0.022,0.268,0.106,0.373c0.084,0.105,0.207,0.172,0.34,0.185C88.451,52.247,90,53.949,90,56c0,2.206-1.794,4-4,4 h-5.5c-0.276,0-0.5,0.224-0.5,0.5s0.224,0.5,0.5,0.5H86c2.757,0,5-2.243,5-5c0-2.397-1.689-4.413-4.003-4.877 C86.999,51.082,87,51.041,87,51c0-2.757-2.243-5-5-5c-1.176,0-2.293,0.416-3.183,1.164C78.219,44.76,76.055,43,73.5,43L73.5,43z"
      ></path>
      <path
        fill="#472b29"
        d="M72 50c-1.403 0-2.609.999-2.913 2.341C68.72 52.119 68.301 52 67.875 52c-1.202 0-2.198.897-2.353 2.068C65.319 54.022 65.126 54 64.938 54c-1.529 0-2.811 1.2-2.918 2.732C62.01 56.87 62.114 56.99 62.251 57c.006 0 .012 0 .018 0 .13 0 .24-.101.249-.232.089-1.271 1.151-2.268 2.419-2.268.229 0 .47.042.738.127.022.007.045.01.067.01.055 0 .11-.02.156-.054C65.962 54.537 66 54.455 66 54.375c0-1.034.841-1.875 1.875-1.875.447 0 .885.168 1.231.473.047.041.106.063.165.063.032 0 .063-.006.093-.019.088-.035.148-.117.155-.212C69.623 51.512 70.712 50.5 72 50.5c.208 0 .425.034.682.107.023.007.047.01.07.01.109 0 .207-.073.239-.182.038-.133-.039-.271-.172-.309C72.517 50.04 72.256 50 72 50L72 50zM85.883 51.5c-1.326 0-2.508.897-2.874 2.182-.038.133.039.271.172.309C83.205 53.997 83.228 54 83.25 54c.109 0 .209-.072.24-.182C83.795 52.748 84.779 52 85.883 52c.117 0 .23.014.342.029.012.002.023.003.035.003.121 0 .229-.092.246-.217.019-.137-.077-.263-.214-.281C86.158 51.516 86.022 51.5 85.883 51.5L85.883 51.5z"
      ></path>
      <path
        fill="#fff"
        d="M15.5 51h-10C5.224 51 5 50.776 5 50.5S5.224 50 5.5 50h10c.276 0 .5.224.5.5S15.777 51 15.5 51zM18.5 51h-1c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h1c.276 0 .5.224.5.5S18.777 51 18.5 51zM23.491 53H14.5c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h8.991c.276 0 .5.224.5.5S23.767 53 23.491 53zM12.5 53h-1c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h1c.276 0 .5.224.5.5S12.777 53 12.5 53zM9.5 53h-2C7.224 53 7 52.776 7 52.5S7.224 52 7.5 52h2c.276 0 .5.224.5.5S9.777 53 9.5 53zM15.5 55h-2c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h2c.276 0 .5.224.5.5S15.776 55 15.5 55zM18.5 46c-.177 0-.823 0-1 0-.276 0-.5.224-.5.5 0 .276.224.5.5.5.177 0 .823 0 1 0 .276 0 .5-.224.5-.5C19 46.224 18.776 46 18.5 46zM18.5 48c-.177 0-4.823 0-5 0-.276 0-.5.224-.5.5 0 .276.224.5.5.5.177 0 4.823 0 5 0 .276 0 .5-.224.5-.5C19 48.224 18.776 48 18.5 48zM23.5 50c-.177 0-2.823 0-3 0-.276 0-.5.224-.5.5 0 .276.224.5.5.5.177 0 2.823 0 3 0 .276 0 .5-.224.5-.5C24 50.224 23.776 50 23.5 50z"
      ></path>
      <g>
        <path
          fill="#fff"
          d="M72.5 24h-10c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h10c.276 0 .5.224.5.5S72.776 24 72.5 24zM76.5 24h-2c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h2c.276 0 .5.224.5.5S76.776 24 76.5 24zM81.5 26h-10c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h10c.276 0 .5.224.5.5S81.777 26 81.5 26zM69.5 26h-1c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h1c.276 0 .5.224.5.5S69.776 26 69.5 26zM66.47 26H64.5c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h1.97c.276 0 .5.224.5.5S66.746 26 66.47 26zM75.5 22h-5c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h5c.276 0 .5.224.5.5S75.777 22 75.5 22zM72.5 28h-2c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h2c.276 0 .5.224.5.5S72.776 28 72.5 28z"
        ></path>
      </g>
      <g>
        <path
          fill="#a3a3cd"
          d="M52 26.75A23.25 23.25 0 1 0 52 73.25A23.25 23.25 0 1 0 52 26.75Z"
        ></path>
        <path
          fill="#472b29"
          d="M52,73.95c-13.206,0-23.95-10.744-23.95-23.95S38.794,26.05,52,26.05S75.95,36.794,75.95,50 S65.206,73.95,52,73.95z M52,27.45c-12.434,0-22.55,10.115-22.55,22.55S39.566,72.55,52,72.55S74.55,62.435,74.55,50 S64.434,27.45,52,27.45z"
        ></path>
      </g>
      <g>
        <path fill="#565fa1" d="M52 30.5A19.5 19.5 0 1 0 52 69.5A19.5 19.5 0 1 0 52 30.5Z"></path>
      </g>
      <g>
        <path
          fill="#472b29"
          d="M70.623,44.987c-0.214,0-0.412-0.138-0.478-0.353c-0.084-0.275-0.175-0.548-0.271-0.816 c-0.375-1.048-0.849-2.069-1.408-3.036c-0.139-0.239-0.057-0.545,0.182-0.683c0.24-0.142,0.545-0.058,0.683,0.183 c0.589,1.019,1.088,2.095,1.483,3.197c0.102,0.283,0.197,0.571,0.287,0.86c0.081,0.265-0.067,0.544-0.331,0.625 C70.721,44.98,70.671,44.987,70.623,44.987z"
        ></path>
      </g>
      <g>
        <path
          fill="#472b29"
          d="M52,70.035c-11.047,0-20.035-8.961-20.035-19.976c0-11.014,8.987-19.975,20.035-19.975 c5.734,0,11.204,2.457,15.007,6.74c0.287,0.323,0.564,0.657,0.831,1c0.169,0.218,0.13,0.532-0.087,0.701 c-0.217,0.173-0.532,0.13-0.702-0.088c-0.253-0.325-0.516-0.642-0.789-0.949c-3.614-4.07-8.811-6.404-14.259-6.404 c-10.496,0-19.035,8.512-19.035,18.975S41.504,69.035,52,69.035s19.035-8.513,19.035-18.976c0-0.955-0.072-1.912-0.213-2.846 c-0.041-0.273,0.147-0.528,0.42-0.569c0.274-0.043,0.528,0.146,0.569,0.419c0.149,0.983,0.224,1.991,0.224,2.996 C72.035,61.074,63.047,70.035,52,70.035z"
        ></path>
      </g>
      <g>
        <path
          fill="#fdfcef"
          d="M36.5,73.5c0,0,1.567,0,3.5,0s3.5-1.567,3.5-3.5c0-1.781-1.335-3.234-3.055-3.455 C40.473,66.366,40.5,66.187,40.5,66c0-1.933-1.567-3.5-3.5-3.5c-1.032,0-1.95,0.455-2.59,1.165 c-0.384-1.808-1.987-3.165-3.91-3.165c-2.209,0-4,1.791-4,4c0,0.191,0.03,0.374,0.056,0.558C26.128,64.714,25.592,64.5,25,64.5 c-1.228,0-2.245,0.887-2.455,2.055C22.366,66.527,22.187,66.5,22,66.5c-1.933,0-3.5,1.567-3.5,3.5s1.567,3.5,3.5,3.5s7.5,0,7.5,0 V74h7V73.5z"
        ></path>
        <path
          fill="#472b29"
          d="M38.25 69C38.112 69 38 68.888 38 68.75c0-1.223.995-2.218 2.218-2.218.034.009.737-.001 1.244.136.133.036.212.173.176.306-.036.134-.173.213-.306.176-.444-.12-1.1-.12-1.113-.118-.948 0-1.719.771-1.719 1.718C38.5 68.888 38.388 69 38.25 69zM31.5 73A.5.5 0 1 0 31.5 74 .5.5 0 1 0 31.5 73z"
        ></path>
        <path
          fill="#472b29"
          d="M40,74h-3.5c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5H40c1.654,0,3-1.346,3-3 c0-1.496-1.125-2.768-2.618-2.959c-0.134-0.018-0.255-0.088-0.336-0.196s-0.115-0.244-0.094-0.377C39.975,66.314,40,66.16,40,66 c0-1.654-1.346-3-3-3c-0.85,0-1.638,0.355-2.219,1c-0.125,0.139-0.321,0.198-0.5,0.148c-0.182-0.049-0.321-0.195-0.36-0.379 C33.58,62.165,32.141,61,30.5,61c-1.93,0-3.5,1.57-3.5,3.5c0,0.143,0.021,0.28,0.041,0.418c0.029,0.203-0.063,0.438-0.242,0.54 c-0.179,0.102-0.396,0.118-0.556-0.01C25.878,65.155,25.449,65,25,65c-0.966,0-1.792,0.691-1.963,1.644 c-0.048,0.267-0.296,0.446-0.569,0.405C22.314,67.025,22.16,67,22,67c-1.654,0-3,1.346-3,3s1.346,3,3,3h7.5 c0.276,0,0.5,0.224,0.5,0.5S29.776,74,29.5,74H22c-2.206,0-4-1.794-4-4s1.794-4,4-4c0.059,0,0.116,0.002,0.174,0.006 C22.588,64.82,23.711,64,25,64c0.349,0,0.689,0.061,1.011,0.18C26.176,61.847,28.126,60,30.5,60c1.831,0,3.466,1.127,4.153,2.774 C35.333,62.276,36.155,62,37,62c2.206,0,4,1.794,4,4c0,0.048-0.001,0.095-0.004,0.142C42.739,66.59,44,68.169,44,70 C44,72.206,42.206,74,40,74z"
        ></path>
        <path
          fill="#472b29"
          d="M34.5,73c-0.159,0-0.841,0-1,0c-0.276,0-0.5,0.224-0.5,0.5c0,0.276,0.224,0.5,0.5,0.5 c0.159,0,0.841,0,1,0c0.276,0,0.5-0.224,0.5-0.5C35,73.224,34.776,73,34.5,73z"
        ></path>
      </g>
      <g>
        <path
          fill="#fefdef"
          d="M63.54,41.83c0.285-1.585,0.115-3.499-0.525-5.417c-3.22,0-5.701,2.19-6.124,2.588h-9.214 c-0.424-0.397-2.904-2.588-6.124-2.588c-0.576,1.728-0.77,3.45-0.597,4.934C39.144,42.992,38,45.359,38,48c0,4.971,4.029,9,9,9 h1.835c-1.441,0.66-2.493,2-2.756,3.615c-1.619,0.165-3.578,0.015-4.526-1.407c-1.77-2.655-2.655-2.655-3.54-2.655 c-0.885,0-0.885,0.885,0,1.77c0.885,0.885,0.885,0.885,1.77,2.655c0.729,1.458,2.612,3.109,6.218,2.661v3.331 c0,0.652,0.017,1.129,0.25,1.696c2.167,0.75,6.296,1.312,11.418,0.031C57.875,68.159,58,67.581,58,66.97v-5.575 c0-1.956-1.166-3.631-2.835-4.396H57c4.971,0,9-4.029,9-9C66,45.609,65.061,43.441,63.54,41.83z"
        ></path>
        <path
          fill="#472b29"
          d="M51.699,69.961c-2.909,0-4.878-0.567-5.612-0.821c-0.135-0.047-0.244-0.149-0.299-0.282 c-0.267-0.649-0.288-1.191-0.288-1.887v-2.78c-4.171,0.297-5.766-2.19-6.165-2.988c-0.849-1.698-0.849-1.698-1.676-2.525 c-0.688-0.688-0.961-1.433-0.73-1.99c0.167-0.402,0.562-0.634,1.083-0.634c1.193,0,2.175,0.206,3.956,2.878 c0.755,1.132,2.356,1.297,3.694,1.218c0.246-1.025,0.785-1.942,1.54-2.648H47c-5.238,0-9.5-4.262-9.5-9.5 c0-2.578,1.066-5.06,2.933-6.851c-0.136-1.528,0.085-3.215,0.646-4.896c0.068-0.204,0.259-0.342,0.474-0.342 c3.13,0,5.579,1.933,6.318,2.588h8.827c0.739-0.655,3.188-2.588,6.317-2.588c0.215,0,0.406,0.138,0.474,0.342 c0.623,1.866,0.827,3.778,0.584,5.417C65.64,43.419,66.5,45.656,66.5,48c0,5.238-4.262,9.5-9.5,9.5h-0.201 c1.065,0.994,1.701,2.4,1.701,3.896v5.575c0,0.633-0.123,1.273-0.365,1.905c-0.058,0.152-0.187,0.267-0.346,0.307 C55.471,69.763,53.42,69.961,51.699,69.961z M46.636,68.269c1.339,0.423,5.168,1.322,10.651,0.007 c0.142-0.437,0.213-0.874,0.213-1.305v-5.575c0-1.688-0.998-3.234-2.543-3.94c-0.214-0.099-0.331-0.332-0.281-0.562 c0.05-0.229,0.253-0.394,0.489-0.394H57c4.687,0,8.5-3.813,8.5-8.5c0-2.17-0.825-4.239-2.324-5.827 c-0.109-0.115-0.156-0.275-0.128-0.431c0.257-1.431,0.116-3.13-0.396-4.82c-2.837,0.153-5,2.05-5.42,2.442 c-0.092,0.087-0.215,0.136-0.342,0.136h-9.214c-0.127,0-0.25-0.049-0.342-0.136c-0.419-0.393-2.583-2.289-5.42-2.442 c-0.459,1.518-0.62,3.02-0.462,4.366c0.019,0.16-0.041,0.319-0.16,0.428C39.518,43.328,38.5,45.619,38.5,48 c0,4.687,3.813,8.5,8.5,8.5h1.835c0.235,0,0.438,0.164,0.488,0.394c0.05,0.229-0.066,0.463-0.28,0.561 c-1.315,0.603-2.239,1.814-2.471,3.241c-0.037,0.224-0.218,0.395-0.443,0.417c-2.395,0.243-4.122-0.32-4.993-1.627 c-1.622-2.433-2.352-2.433-3.124-2.433c-0.127,0-0.169,0.028-0.169,0.029c-0.019,0.059,0.054,0.418,0.523,0.888 c0.943,0.942,0.977,1.012,1.864,2.785c0.343,0.685,1.769,2.884,5.709,2.389c0.142-0.019,0.286,0.026,0.393,0.121 c0.107,0.095,0.169,0.231,0.169,0.375v3.331C46.5,67.496,46.51,67.87,46.636,68.269z"
        ></path>
      </g>
      <g>
        <path fill="#fefdef" d="M63.098,50.905c-0.852,1.888-2.722,3.456-6.014,3.72"></path>
        <path
          fill="#472b29"
          d="M57.083,54.875c-0.129,0-0.238-0.1-0.249-0.23c-0.011-0.137,0.091-0.258,0.229-0.269 c3.596-0.288,5.142-2.103,5.806-3.573c0.057-0.127,0.204-0.183,0.331-0.125c0.125,0.057,0.182,0.204,0.125,0.33 c-1.017,2.254-3.227,3.627-6.222,3.866C57.097,54.875,57.09,54.875,57.083,54.875z"
        ></path>
      </g>
      <g>
        <path fill="#fefdef" d="M63.592,47.165c0.105,0.713,0.103,1.469-0.028,2.217"></path>
        <path
          fill="#472b29"
          d="M63.564,49.632c-0.015,0-0.029-0.001-0.044-0.004c-0.136-0.023-0.227-0.153-0.203-0.289 c0.122-0.693,0.131-1.432,0.027-2.138c-0.021-0.136,0.074-0.264,0.21-0.283c0.133-0.018,0.264,0.074,0.284,0.211 c0.114,0.768,0.104,1.541-0.029,2.296C63.789,49.547,63.683,49.632,63.564,49.632z"
        ></path>
      </g>
      <g>
        <path fill="#fefdef" d="M61.816,43.819c0.599,0.426,1.085,1.116,1.408,1.946"></path>
        <path
          fill="#472b29"
          d="M63.224,46.015c-0.1,0-0.194-0.061-0.233-0.159c-0.315-0.809-0.771-1.442-1.32-1.832 c-0.112-0.08-0.139-0.236-0.059-0.349c0.081-0.113,0.237-0.14,0.349-0.06c0.628,0.447,1.146,1.159,1.496,2.059 c0.05,0.129-0.014,0.273-0.142,0.324C63.285,46.009,63.254,46.015,63.224,46.015z"
        ></path>
      </g>
    </svg>
  )
}

export default GithubFooter
